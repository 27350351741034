
import Vue from 'vue'
import userAccountBody from '@/components/pages/whitelistID/index.vue'
import SERVICE_CONSTANTS from '@/constants/services'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'whitelistIDPage',
  computed: {
    ...mapGetters('user', ['getIsAuthenticated'])
  },
  methods: {
    preLogout (): void {
      const vm = this as any
      vm.$store.dispatch('popupModule/openPopup', {
        type: 'confirmPopup',
        letDefaultClose: false,
        title: vm.$t('popups.titles.confirmation'),
        content: vm.$t('popups.content.wouldExit'),
        actions: [
          {
            title: vm.$t('popups.actions.stay'),
            isAccent: false,
            callback () { vm.$store.commit('popupModule/setClosed') }
          },
          {
            title: vm.$t('popups.actions.yepExit'),
            isAccent: true,
            callback () {
              vm.$store.commit('popupModule/setClosed')
              vm.logout()
            }
          }
        ]
      })
    },
    async logout (): Promise<void> {
      const vm = this as any
      const cookie = vm.$cookies.get('jwtr')
      const response = await vm.$services.authorization.user.logout({ refresh: cookie })
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      if (response.success) {
        vm.$cookies.set('jwtr', '', {
          expires: -1,
          domain: '.' + host
        })
        window.location.replace(SERVICE_CONSTANTS.PASSPORT_URL)
      }
    }
  },
  components: {
    userAccountBody
  }
})
