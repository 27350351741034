var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"whitelistID"},[_c('v-row',[(_vm.miw(1200))?_c('v-col',{staticClass:"pr-4 fill-height",attrs:{"cols":"3"}},[_c('aside',{staticClass:"whitelistID_aside"},[_c('v-list',{staticClass:"py-5"},[(_vm.getIsAuthenticated)?_c('v-list-item',{staticClass:"whitelistID_nav-item mb-1 pa-0",class:{
              'whitelistID_nav-item--active': _vm.$route.name === 'whitelistIDUserAccount'
            }},[_c('v-list-item-title',{staticClass:"fill-height fill-width"},[_c('router-link',{staticClass:"whitelistID_nav-link fill-height fill-width px-2 d-flex align-center",attrs:{"to":{
                  name: 'whitelistIDUserAccount'
                }}},[_vm._v(" "+_vm._s(_vm.$t('asideNav.myAccount'))+" ")])],1)],1):_vm._e(),(!_vm.isTrustedEnvironment)?_c('v-list-item',{staticClass:"whitelistID_nav-item mb-1 pa-0",class:{
              'whitelistID_nav-item--active': _vm.$route.name === 'whitelistIDAbout'
            }},[_c('v-list-item-title',{staticClass:"fill-height fill-width"},[_c('router-link',{staticClass:"whitelistID_nav-link fill-height fill-width px-2 d-flex align-center",attrs:{"to":{
                  name: 'whitelistIDAbout'
                }}},[_vm._v(" "+_vm._s(_vm.$t('asideNav.aboutDeveloper'))+" ")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"whitelistID_nav-item mb-1 pa-0",class:{
              'whitelistID_nav-item--active': _vm.$route.name === 'whitelistIDLegalInformation'
            }},[_c('v-list-item-title',{staticClass:"fill-height fill-width"},[_c('router-link',{staticClass:"whitelistID_nav-link fill-height fill-width px-2 d-flex align-center",attrs:{"to":{
                  name: 'whitelistIDLegalInformation'
                }}},[_vm._v(" "+_vm._s(_vm.$t('asideNav.legalInfo'))+" ")])],1)],1)],1),(_vm.getIsAuthenticated)?_c('v-btn',{staticClass:"btn btn--gradient-border mt-auto",attrs:{"height":"28","depressed":""},on:{"click":_vm.preLogout}},[_vm._v(" "+_vm._s(_vm.$t('asideNav.exitBtn'))+" ")]):_vm._e()],1)]):_vm._e(),_c('v-col',{staticClass:"fill-height",attrs:{"cols":_vm.miw(1200) ? 9 : 12}},[_c('user-account-body')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }